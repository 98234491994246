export class KisiModel {
    guid: string;
    kullaniciId: number;
    kullaniciAdi: string;
    alinanSiparisOnaylayabilir: boolean;
    satinAlmaTalebiOnaylayabilir: boolean;
    alinanTeklifOnaylayabilir: boolean
    verilenTeklifOnaylayabilir: boolean;
    satinAlmaTalebiMiktar: number;
    alinanSiparisMiktar: number;
    projeKodu: string;
    srmMrkKodu: string;
    firmaNo: number;
    subeNo: number;
    kasaKodu: string;
    evrakSeri: string;
    kullaniciNo: number;
    depoNo: number;
    lastUpdate: Date;
    lastUpdateUserId: number;
}