import { Injectable, isDevMode } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { CurrentUser } from "./current-user";
import { environment } from "environments/environment";
import { LoginComponent } from "./login/login.component";
import { TalepOnayComponent } from "./talep/talep-onay/talep-onay.component";

@Injectable()
export class AuthService {
  currentUser: CurrentUser;
  ApiUrl: string;
  private _DepoNo: number = 1;
  private _SrmMerkezi: string = "";
  private _ProjeKodu: string = "";
  private _FirmaNo: number = 1;
  private _SubeNo: number = 1;
  private _EvrakSeri: string = "";
  private _kullaniciNo = 1;
  private _kasaKodu = "";
  constructor(private http: HttpClient) { }

  signout() {
    this.currentUser = null;
  }

  signin(signinFormValue): Observable<CurrentUser> {
    return this.http
      .post<CurrentUser>(environment.authUrl + "authenticate", {
        UserName: signinFormValue.email,
        Password: signinFormValue.password,
        LoginType: 1,
      })
      .pipe(
        map((user) => {
          if (user && user.accessToken) {
            this.currentUser = user;
          }

          this.currentUser.attributes.forEach((element) => {
            if (element.attributeKey == "ApiUrl") {
              // if (isDevMode) {
              //   this.ApiUrl = "http://localhost:6800/api/";
              // } else 
              {
                this.ApiUrl = element.attributeValue;
              }
            }
            // else if (element.attributeKey == "DepoNo") {
            //   this.DepoNo = Number.parseInt(element.attributeValue);
            // } else if (element.attributeKey == "SrmMerkezi") {
            //   this.SrmMerkezi = element.attributeValue;
            // } else if (element.attributeKey == "ProjeKodu") {
            //   this.ProjeKodu = element.attributeValue;
            // } else if (element.attributeKey == "FirmaNo") {
            //   this.FirmaNo = Number.parseInt(element.attributeValue);
            // } else if (element.attributeKey == "SubeNo") {
            //   this.SubeNo = Number.parseInt(element.attributeValue);
            // } else if (element.attributeKey == "EvrakSeri") {
            //   this.EvrakSeri = element.attributeValue;
            // }
          });


          // console.log("Kayittan cekilen kullanici bilgielri ", kullaniciBilgileri);


          // this.ProjeKodu = kullaniciBilgileri["projeKodu"];

          // this.EvrakSeri = kullaniciBilgileri["evrakSeri"];


          return <CurrentUser>user;
        })
      );
  }

  refreshToken(): Observable<CurrentUser> {
    const atoken = this.currentUser.accessToken;
    const rtoken = this.currentUser.refreshToken;
    return this.http
      .post<CurrentUser>(environment.authUrl + "refresh", {
        token: atoken,
        refreshToken: rtoken,
      })
      .pipe(
        map((user) => {
          if (user && user.accessToken) {
            this.currentUser = user;
          }
          return <CurrentUser>user;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }

  getAuthToken(): string {
    if (this.currentUser != null) {
      return this.currentUser.accessToken;
    } else return "";
  }

  public get SubeNo(): number {
    var kullaniciBilgileri = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    this._SubeNo = kullaniciBilgileri["subeNo"];
    return this._SubeNo;
  }

  public get SrmMerkezi(): string {

    var kullaniciBilgileri = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    this._SrmMerkezi = kullaniciBilgileri["srmMrkKodu"];
    return this._SrmMerkezi;
  }

  public get DepoNo(): number {
    var kullaniciBilgileri = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    this._DepoNo = kullaniciBilgileri["depoNo"];
    return this._DepoNo;
  }

  public get ProjeKodu(): string {

    var kullaniciBilgileri = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    this._ProjeKodu = kullaniciBilgileri["projekodu"];
    return this._ProjeKodu;
  }

  public get FirmaNo(): number {

    var kullaniciBilgileri = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    this._FirmaNo = kullaniciBilgileri["firmaNo"];
    return this._FirmaNo;
  }

  public get EvrakSeri(): string {

    var kullaniciBilgileri = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    this._EvrakSeri = kullaniciBilgileri["evrakSeri"];
    return this._EvrakSeri;
  }

  public get kullaniciNo() {
    var kullaniciBilgileri = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    this._kullaniciNo = kullaniciBilgileri["kullaniciNo"];

    return this._kullaniciNo;
  }

  public get kasaKodu() {
    var kullaniciBilgileri = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    this._kasaKodu = kullaniciBilgileri["kasaKodu"];
    return this._kasaKodu;
  }

}

